<template>
  <v-container
    id="products-list"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="indigo"
          icon="mdi-clipboard-list"
          title="Selected Products"
        >
          <v-row class="text-right">
            <v-col
              cols="12"
              class="mt-2"
            >
              <v-btn
                class="ma-1"
                :disabled="b2bSelectedProductsList.length === 0"
                @click="clearList"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Clear list
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                :disabled="b2bSelectedProductsList.length === 0"
                @click="dialog = true"
              >
                <v-icon left>
                  mdi-send
                </v-icon>
                Send list
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-simple-table
                class="ml-2"
              >
                <thead>
                  <tr>
                    <th class="text-left">
                      Description
                    </th>
                    <th class="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, i) in b2bSelectedProductsList"
                    :key="i"
                  >
                    <td
                      class="text-left"
                    >
                      <a :href="product.url">{{ product.description + (product.variant ? ` - Color ${product.variant.description}` : '') }}</a>
                    </td>
                    <td class="text-right">
                      <v-btn
                        v-for="(action, ia) in selectedActions"
                        :key="ia"
                        :color="action.color"
                        class="px-1 ml-1"
                        fab
                        x-small
                        @click="action.action(product)"
                      >
                        <v-icon v-text="action.icon" />
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-history"
          title="Recently Viewed Products"
        >
          <v-row>
            <v-col
              cols="12"
              class="mt-10"
            >
              <v-simple-table
                class="ml-2"
              >
                <thead>
                  <tr>
                    <th class="text-left">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, i) in b2bLastViewedProductsList"
                    :key="i"
                  >
                    <td
                      class="text-left"
                    >
                      <a :href="product.url">{{ product.description }}</a>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card class="text-center">
        <v-card-title>
          {{ $vuetify.breakpoint.mobile ? 'Send list to a Sales Repr.' : 'Send list to a Sales Representative' }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <small class="primary--text">All fields except Brand are mandatory</small>

          <v-form
            ref="form"
            v-model="formValid"
            lazy-validation
          >
            <v-text-field
              v-model="businessName"
              label="Business Name"
              outlined
              dense
              required
            />

            <v-text-field
              v-model="brand"
              label="Brand"
              outlined
              dense
            />

            <v-select
              v-model="industry"
              :items="industries"
              label="Industry"
              outlined
              dense
              required
            />

            <v-text-field
              v-model="name"
              label="Name"
              outlined
              dense
              required
            />

            <v-text-field
              v-model="email"
              label="Email"
              outlined
              dense
              type="email"
              required
              :rules="[rules.email]"
            />

            <v-text-field
              v-model="web"
              label="Web"
              outlined
              dense
              required
            />

            <v-text-field
              v-model="phone"
              label="Phone Number"
              outlined
              dense
              required
            />

            <v-select
              v-model="role"
              :items="roles"
              label="Role"
              outlined
              dense
              required
            />

            <v-checkbox
              v-model="clearAfter"
              input-value="true"
              label="Clear list after sending"
              class="mt-0 mb-0 pt-0"
              value
              dense
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="dialog = false"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Cancel
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!enableSend || !formValid"
            @click="sendList"
          >
            <v-icon left>
              mdi-send
            </v-icon>
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="resultSnackbar"
      :color="resultSnackbarType"
      bottom
      center
    >
      {{ resultSnackbarType === 'success' ? 'Email sent successfully' : 'Sorry, an error occured sending the email' }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import { API } from 'aws-amplify'

  export default {
    data () {
      return {
        formValid: false,
        businessName: undefined,
        brand: undefined,
        industry: undefined,
        name: undefined,
        phone: undefined,
        email: undefined,
        web: undefined,
        role: undefined,
        clearAfter: true,
        dialog: false,
        resultSnackbar: false,
        resultSnackbarType: 'success',
        rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail address.'
          },
        },
        b2bLastViewedProductsList: JSON.parse(localStorage.b2bLastViewedProductsList || '[]'),
        b2bSelectedProductsList: JSON.parse(localStorage.b2bSelectedProductsList || '[]'),
        selectedActions: [
          {
            color: 'gray',
            icon: 'mdi-delete',
            action: (item) => { this.removeProduct(item) },
          },
        ],
        industries: [
          'Accessories',
          'Automotive',
          'Cases, Display cabinets, Boxes',
          'Designer',
          'Furniture',
          'Garments',
          'Genuine leathers',
          'Labels, high frequency, printing',
          'Lamination, fabrics, synthetic leather',
          'Leathergoods',
          'Medical',
          'Nautical',
          'Shoes',
          'Wholesaler',
          'Other',
        ],
        roles: [
          'Buyer',
          'Owner',
          'Pattern maker',
          'R&D',
          'Sales, agent',
          'Style dept',
          'Other',
        ],
      }
    },

    computed: {
      enableSend () {
        return this.businessName &&
          this.industry &&
          this.name &&
          this.phone &&
          this.email &&
          this.web &&
          this.role
      },
    },

    methods: {
      async sendList () {
        const apiName = 'listeProdottiApi'
        const path = '/listeProdotti'
        const myInit = {
          body: {
            id: parseInt(new Date().toJSON().replaceAll('-', '').replaceAll('T', '').replaceAll(':', '').substring(0, 14) + '' + Math.floor(Math.random() * 1000000)),
            businessName: this.businessName,
            brand: this.brand,
            industry: this.industry,
            name: this.name,
            phone: this.phone,
            email: this.email,
            web: this.web,
            role: this.role,
            products: JSON.stringify(this.b2bSelectedProductsList),
          },
        }

        API
          .post(apiName, path, myInit)
          .then(response => {
            this.dialog = false
            this.resultSnackbarType = 'success'
            this.resultSnackbar = true
            if (this.clearAfter) {
              this.clearList()
              this.$refs.form.reset()
            }
          })
          .catch(error => {
            /* eslint no-console: 0 */
            console.error(error.response)
            this.resultSnackbarType = 'error'
            this.resultSnackbar = true
          })
      },
      removeProduct (currProduct) {
        if (currProduct) {
          this.b2bSelectedProductsList = this.b2bSelectedProductsList.filter(item => item.id !== currProduct.id)
          localStorage.b2bSelectedProductsList = JSON.stringify(this.b2bSelectedProductsList)
        }
      },
      clearList () {
        this.b2bSelectedProductsList.forEach(element => {
          this.removeProduct(element)
        })
      },
    },
  }
</script>
